//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

import cm from "./SearchCover.module.scss"
import { useIntl } from "react-intl";
import { withPrefix } from "gatsby";

interface Props {
  resultCount: number | null;
  searchWord: string | null;
  searchInputText: string;
  loading: boolean;
  onInput(newSearchInputText: string): void;
  onSearchClick(): void;
}

const callOnEnter = (fnToCall: () => void) => (e: React.KeyboardEvent<any>) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    e.stopPropagation();
    fnToCall();
  }
}

const SearchCover: React.FC<Props> = ({ searchWord, resultCount, searchInputText, onInput, onSearchClick, loading }) => {
  const { formatMessage: fm } = useIntl();
  return (
    <div className={cm.searchCover}>

      <img src={withPrefix("/images/cover-1.jpg")} alt="" />

      <div className={cm.form}>
        {loading && <h3>{fm({ id: "search.loading" })}</h3>}
        {!!searchWord && !loading && <h3>{fm({ id: "search.results" }, { resultCount, searchWord })}</h3>}

        <div className={cm.search} >
          <form onSubmit={e => e.preventDefault()}>
            <input
              type="search"
              name="keyword"
              placeholder={fm({ id: "search.inputplaceholder" })}
              value={searchInputText}
              onChange={({ currentTarget: { value } }) => onInput(value)}
              onKeyDown={callOnEnter(onSearchClick)}
              title={fm({ id: "search.inputtooltip" })}
            />
            <button type="submit" onClick={onSearchClick}>{fm({ id: "search.searchbtn" })}</button>
          </form>
        </div>
      </div>

    </div>
  )
}

export default SearchCover
